/* 中文 */
export default {
  emailRegister: '邮箱注册',
  registerNotice: '未注册的账户将自动注册并登录',
  enterEmail: '请输入您的邮箱地址',
  enterCode: '请输入您的验证码',
  getCode: '获取验证码',
  signIn: '立即登录',
  signPropt: '登录即表示您同意',
  policy: '隐私政策',
  agreement: '用户协议',
  emailRuleError: '邮箱格式不正确',
  regisgerAndEnjoy: `注册享受精彩好剧`,

  starcard: `星卡会员权益`,
  more: `更多`,
  describe: `星卡是聚宝盆推广系统的权益证明。只有拥有星卡才能享受聚宝盆推广系统的一系列福利和权益。星卡分为体验星卡和专属星卡，星卡用户可以获得现金奖励。`,
  starReward: `星卡推广奖励`,
  unlock: `解锁`,
  text1: `· 邀请奖励：直接或间接邀请用户购买星卡会员可获得10%和5%的奖励。`,
  text2: `· 投资版权推广奖励：直接或间接邀请用户投资版权推广，获得日收益的10%和5%的奖励。`,
  text3: `· 观影奖励：直接邀请用户解锁单集可享受40%的奖励，或订阅短剧会员可获得20%的奖励。`,
  starClu: `星群奖励`,
  text4: `具体奖励规则请咨询官方客服或邀请人`,
  copyright: `版权众筹`,
  td1: `年化收益率`,
  td2: `初始投资金额`,
  td3: `已完成/总金额`,
  warning: `*根据推广的实际效果，收益会每日波动。`,
  schedule: `众筹进度`,
  buynow: `立即购买`,
  download: `下载`,
  go: `前往`,
  android: `安卓`,
  apple: `应用商店`,
  Characteristic: `特点`,
  Participation: `参与金额`,
  method: `支付方式`,
  amount: `支付金额`,
  jump: `跳转支付`,
  name1: `短剧`,
  name2: `多种类型`,
  name3: `星卡会员`,
  name4: `管道收入`,
  describe1: `质量保证`,
  describe2: `想你所想`,
  describe3: `百亿补贴`,
  describe4: `副业之首`,
  warning1: `输入内容不能为空`,
  success1: `绑定成功`,
  ends: `倒计时结束`,
  warning2: `您还未登录，请先登录`,

  experienceStar: '体验星卡',
  ExclusiveStar: '专享星卡',
  noCard: '无星卡',

  aboutus: `关于我们`,
  understand: `了解我们`,
  Snapshort: `${window.name}团队：`,
  Innovators: `重塑`,
  industry: `影视行业的创新者`,
  paragraph1_1: `在瞬息万变的数字时代，一群热爱影视艺术的开发者正致力于重塑观众的观影体验。`,
  paragraph1_2: `我们的团队，${window.name}，以创新为核心，以技术为驱动，正在引领影视行业的新趋势。`,
  paragraph1_3: `${window.name}是一款专注于短剧内容的移动应用。我们精心挑选高质量的短剧，以适应现代观众快节奏的生活方式。`,
  paragraph1_4: `我们的应用程序让用户随时随地观看最新和最热门的短剧，同时提供丰富的个性化推荐，让每个用户都能找到自己喜欢的内容。`,
  paragraph2_1: `我们的团队由一群经验丰富、技术精湛的开发人员组成，他们精通影视内容制作、大数据分析、人工智能推荐等技术。`,
  paragraph2_2: `这使我们能够高效地生产和更新内容，同时为每个用户提供定制化的推荐。`,
  paragraph2_3: `我们的愿景是让每个用户在剧集重力中找到自己的乐趣和灵感。我们相信，通过我们的努力，${window.name}将成为您生活的一部分，让您在繁忙的生活中找到片刻的宁静和欢乐。`,
  paragraph3_1: `无论您是寻找轻松愉快的娱乐，还是发人深省的故事，${window.name}都能满足您的需求。`,
  paragraph3_2: `让我们一起探索影视艺术的无限可能。`,

  period: `试用期卡`,
  Premier: `高级卡`,
  Expiration: `到期时间：`,
  renew: `立即续订`,
  balance: `星卡余额`,
  Income: `收入`,
  wallet: `转入钱包`,
  daily: `每日任务`,
  nocompleted: `今日未完成`,
  code: `VIP代码`,
  Residue: `剩余代码`,
  reward: `今日奖励`,
  type: `类型`,
  PaymentAmount: `金额`,
  time: `时间`,
  none: `目前没有更多内容`,
  load: `加载中...`,
  loadmore: `加载更多`,
  success2: `成功转入钱包`,
  five: `五星专家`,
  four: `四星专家`,
  Samsung: `三星专家`,
  two: `二星专家`,
  one: `一星专家`,
  recommendation: `推荐奖励`,
  rewards: `奖励`,
  taskRewards: `任务奖励`,
  starRewards: `版权推广奖励`,
  MovieRewards: `高级卡推广奖励`,

  annualized: `年化收益率`,
  Initial: `初始投资金额`,
  Completed: `已完成/总金额`,
  tishi: `*根据推广的实际效果，收益会每日波动。`,
  Crowdfunding: `众筹进度：`,
  dividend: `众筹红利余额`,
  Project: `项目名称`,
  Cumulative: `累计收入`,
  Dividend: `分红利率`,
  investment: `投资金额`,
  name5: `众筹`,
  name6: `参与`,
  title1: `进行中`,
  title2: `已结束`,
  title3: `参与记录`,
  title4: `收益详情`,
  text5: `购买时间`,
  text6: `释放时间`,
  text7: `累计发放金额`,
  text8: `发放金额`,
  title_9: `账户切换`,
  title_10: `登出`,
  title_11: '兑换码兑换',
  title_13: `IM 聊天`,
  title_12: `交易市场`,

  remaining: `今日剩余任务：`,
  Claim: `认领截止时间：`,
  Submission: `提交截止时间：`,
  Completion: `完成时间：`,
  Complete: `完成`,
  error: `任务审核失败，需要重新提交！`,
  warning3: `提交任务时，图片中的内容必须附带当天日期`,
  sample: `样例图`,
  Copywriting: `文案素材`,
  Claim2: `认领任务`,
  SubmitTask: `提交任务`,
  Waiting: `等待审核`,
  Resubmit: `重新提交`,
  resubmit: `，请重新提交！`,
  Expand: `展开`,
  Recover: `恢复`,
  Deadline: `截止时间：`,
  text9: `1. 图片中的文本内容需要与文案文本匹配`,
  text10: `2. 图片中的文本内容需要附带当天日期`,
  success3: `下载成功`,
  error2: `下载失败`,
  success4: `复制成功`,
  warning4: `请上传已完成的截图`,
  success5: `上传成功`,

  emaillogin: `邮箱登录`,
  warning5: `未注册账号将自动注册并登录`,
  text11: `获取验证码`,
  Sign: `立即登录`,
  text12: `登录即表示您同意`,
  Privacy: `隐私政策`,
  Agreement: `用户协议`,
  warning6: `请输入您的邮箱地址`,
  warning7: `请输入正确的邮箱地址`,
  warning8: `验证码不能为空`,
  warning9: `请输入您的邮箱地址`,
  retrieve: `找回`,
  Payment: `支付确认`,
  InformationFilling: `信息填写`,
  BalanceCombinationPayment: `余额组合支付`,
  AvailableBalance: `可用余额：`,
  will: `将向`,
  setCode: `发送验证码`,
  BalanceDeduction: `余额抵扣后，仍需支付`,
  StarClusterLevel: `星团等级`,
  PersonalPerformance: `个人业绩`,
  StarClusterPerformance: `星团业绩`,
  memberNumber: `星团会员数`,
  NumberStar: `星团会员数量`,
  NumberDirectly: `直接邀请会员数量`,
  activeMembers: `活跃会员数量`,

  title_1: `登录`,
  title_2: `首页`,
  title_3: `我的钱包`,
  title_4: `我的星卡`,
  title_5: `版权众筹`,
  title_6: `我的社区`,
  title_7: `关于我们`,
  title_8: `社区福利`,

  interests: `星卡权益`,
  log_fir: `请先登录`,
  name7: `免费观看`,
  name8: `每日任务`,
  name9: `星群系统`,
  name10: `更多特权`,
  describe5: `VIP豁免正在等待您`,
  describe6: `百亿专项任务`,
  describe7: `邀请好友，分享奖励`,
  describe8: `等待您来探索`,

  Invite: `邀请好友`,
  invitation: `我的邀请码`,
  directly: `直接邀请好友`,
  indirect: `间接好友`,
  Copy_link: `复制邀请链接`,
  Fill: `填写邀请码`,
  inv_code: `邀请码：`,
  Replicating: `复制成功`,
  error3: `复制失败`,
  error4: `粘贴失败：`,

  Apple_login: `使用Apple登录`,
  Google_login: `使用Google登录`,
  Email_login: `使用邮箱登录`,

  Commission: `佣金：`,
  nolevel: `目前没有可用的等级`,
  community: `社区人数`,
  Community1: `社区众筹返利`,
  promoted: `今日推广人数`,
  Promote: `推广星卡返利`,
  balance2: `当前余额`,
  Withdraw: `提现`,
  Interpersonal: `人际好友推荐`,
  mixpayTips: `优先使用余额,支付使用余额为：`,
  noBalanceL: `余额不足`,

  days: `天`,
  Selected: `已选`,
  Select: `选择`,
  paid: `支付金额`,
  gopay: `立即支付`,
  warning10: `请先选择支付方式`,

  progress: `支付进行中`,
  cancel: `取消`,
  confirm: `确认`,
  Paid: `已支付`,
  warning11: `您的订单支付未成功`,

  Remaining: `本月剩余次数：`,
  Exchange: `兑换列表`,
  Redemption: `兑换码`,
  Duration: `时长`,
  Period2: `有效期`,
  State: `状态`,
  Function: `功能`,
  nouse: `未使用`,
  Used: `已使用`,
  Copy: `复制`,

  Wallet2: `钱包变动历史`,
  Variable: `变动金额($)`,
  OrderID: `订单号`,
  Balance3: `余额提现`,
  Balance5: `余额转账`,
  information: `填写信息`,
  Amount4: `提现金额`,
  Balance6: `转账金额`,
  Balance4: `余额：`,
  nomoney: `余额不足`,
  crowdfunding: `参与众筹`,

  title_name: `用户协议`,
  t1: `本协议自2023年3月15日发布并生效，最新修订日期为2023年3月15日。`,
  t2: `${window.region}${window.company}科技有限公司是本软件的注册版权所有者，并依法享有本软件的版权。本用户协议（以下简称“本协议”）是${window.company}科技有限公司（以下简称“${window.company}科技”、“我们”）与用户（也称“您”）就客户端软件产品“聚优利”（以下简称“本软件”）的安装和使用达成的有效合同。通过点击确认本协议或以其他任何方式选择接受本协议，即表示您已经与${window.company}科技达成协议，并同意接受本协议的全部以下条款。本软件由${window.company}科技运营，并向您提供视频服务，依法享有权利、履行义务，并承担法律责任。`,
  t3: `本协议一旦有变更，软件将在软件页面提示修改。一旦修改后的使用协议显示于公告页面上，即有效代替原协议。您应随时登录本软件查阅最新协议。在使用本软件提供的各项服务之前，您应仔细阅读本协议。若您不同意修改后的本协议，您可以自愿取消本软件提供的服务。否则，您的使用将被视为您对本协议及随时修改的全部内容的接受。`,
  t4: `1、我们如何收集和使用您的个人信息`,
  t5: `个人信息是指以电子或其他方式记录的各种信息，可以单独或与其他信息结合，用以识别特定自然人身份或反映特定自然人活动。`,
  t6: `${window.company}科技及本产品的注册版权所有者在向您提供服务时，仅会按照本政策所述的目的、方式和范围收集、使用您的个人信息：`,
  t7: `1. 应用功能1：成为注册用户`,
  t8: `申请成为本产品的注册用户时，您需要提供以下信息：您的设备信息。`,
  t9: `您提供的上述信息授权我们为您继续使用本服务。当您取消账号时，我们将停止使用并删除上述信息。`,
  t10: `2. 应用功能2：显示应用内的操作日志`,
  t11: `当您使用我们的服务时，本产品将记录您的操作日志，包括浏览和点击记录、添加电视节目、观看电视节目以及参与活动。您提供的上述信息授权我们为您继续使用本服务。当您取消账号时，我们将停止使用并删除上述信息。`,
  t12: `3. 应用功能三：订阅与支付`,
  t13: `当您使用本产品时，订购VIP会员和剧集支付服务时生成的记录，包括订阅记录和消费记录，将存储在我们的服务器上。上述信息对于产品功能是必要的。如果您不同意我们记录上述信息，我们将无法为您提供剧集购买服务。`,
  t14: `4. 应用功能四：安全服务`,
  t15: `为向您提供安全稳定的服务，保障您的账户和财产安全，防止虚假登录，为了您正常使用本产品的功能和服务，当您使用我们的产品或服务时，我们可能会自动记录某些信息，包括：设备或软件信息：您的移动设备、网页浏览器或其他用于访问我们的服务的程序提供的配置信息，您的IP地址，您的移动设备使用的软件版本信息，设备识别代码（IMEI、MAC地址），设备唯一标识符（Android ID、OAID）信息以及设备序列号。如果您不同意我们记录上述信息，您可能无法完成风险控制流程并使用我们的产品。我们还可能自动记录您是否安装了支付宝APP。如果您不同意我们记录上述信息，您对本产品的正常使用将不受影响。`,
  t16: `5. 应用功能五：启用权限`,
  t17: `我们承诺不会超出收集您个人信息的范围或频率，并永远不会收集或使用与本产品服务不必要或没有合理应用场景的信息。`,
  t18: `在使用本产品时，为了向您提供服务，本产品在您使用应用程序的某些场景下可能会请求终端设备的以下权限。当然，您可以拒绝启用以下权限，或在手机设置中关闭本产品的以下权限。关闭以下权限不会影响您使用本产品的其他功能：`,
  t19: `访问电话：通过获取您的电话状态权限，我们记录您的设备识别代码（即IMEI或IMSI代码）进行匿名处理，用于广告展示和安全验证；`,
  t20: `访问本地存储：通过启用本地存储权限，将本产品内的剧集下载到您手机的存储空间；`,
  t21: `访问相册：您可以直接从手机选择照片或图片进行上传或在与客服沟通时提供证明；`,
  t22: `访问设备上的媒体内容和文件：用于缓存读写剧集封面和活动图片，提高应用使用流畅度。`,
  t23: `启用无线数据：您可以通过连接无线或蜂窝网络实现本产品所需的网络功能；`,
  t24: `访问安装程序列表：通过获取您的安装程序列表，我们可以确认您是否安装了我们推广的产品，以便我们及时为您分发相应的奖励。`,
  t25: `打开麦克风和语音识别：您可以通过语音控制功能和语音朗读功能来控制本产品；`,
  t26: `使用剪贴板：您可以复制和粘贴客服电话号码，或选择不使用剪贴板权限。`,
  t27: `获取设备网络权限，用于使用词典、百科全书和语音朗读；`,
  t28: `获取粗略位置：在浏览某些广告时，获取粗略位置权限；`,
  t29: `访问搜索位置：在浏览某些广告时，将获取搜索位置权限；`,
  t30: `使用相机：用户提供反馈时，可能会获得使用相机的权限。`,
  t31: `2、我们如何使用Cookie和类似技术`,
  t32: `Cookie和类似技术在互联网上被广泛使用。为了确保本平台的正常运作，并为您提供更轻松的访问体验，我们可能会在您的移动设备上存储称为Cookie的小数据文件。Cookie通常包含标识符、站点名称以及一些数字和字符。借助Cookie，我们将记录您在本产品中的登录信息和操作信息，以便我们可以存储您的使用习惯，并帮助确定您账户的安全状态，为您提供更轻松的用户体验和服务。`,
  t33: `我们不会将Cookie用于本政策所述以外的任何目的。您可以根据自己的偏好管理或停用Cookie。有关更多信息，请参阅 AboutCookies.org。如果停用Cookie，您可能无法享受最佳的服务体验，并且我们推荐的内容也可能与您的兴趣较少相关。`,
  t34: `3、我们如何分享、转让和公开披露您的个人信息`,
  t35: `（1）分享`,
  t36: `除非以下情况外，我们不会与${window.company}科技及本产品的注册版权所有者和关联公司以外的任何公司、组织或个人分享您的个人信息：`,
  t37: `1. 获得明确同意后分享：在获得您的明确同意后，我们将与其他方分享您的个人信息。`,
  t38: `2. 我们可能会根据法律法规或政府监管部门的强制性规定，与公众分享您的个人信息。`,
  t39: `3. 与授权合作伙伴共享：我们的一些服务将由授权合作伙伴提供，仅用于本政策所述目的。我们可能会与我们的合作伙伴分享您的部分个人信息，以提供更好的客户服务和用户体验。我们只会出于合法、正当、必要、特定、明确的目的分享您的个人信息，并且只会分享提供服务所必需的个人信息。我们的合作伙伴无权将分享的个人信息用于任何其他目的。`,
  t40: `目前，我们的授权合作伙伴包括以下类型：`,
  t41: `1）供应商、服务提供商、第三方代理商和其他合作伙伴。我们向全球支持我们业务的供应商、服务提供商、第三方代理商和其他合作伙伴发送信息，包括提供技术基础设施服务、分析我们服务的使用情况、衡量其效果、提供客户服务、促进支付，或进行学术研究和调查。在您明确事先同意的情况下，我们可能在采用加密和匿名化方法后与第三方代理商分享您的消费记录、订阅记录、充值记录、订单记录、已删除的观看记录和消费活动消费记录。如果您不同意我们分享上述信息，这不会影响您对本产品的正常使用。`,
  t42: `2）分析服务数据服务提供商：为了向您提供更好的服务，我们与包括友盟+在内的第三方数据服务提供商合作，预测您的特征偏好。为了确保您数据的安全，此预测以指数分数的形式提供，并且在严格的去标识化安全措施下进行数据分析，以免泄露您的个人信息。`,
  t43: `3）授权的广告和分析服务合作伙伴。除非我们获得您的许可，否则我们不会与提供广告和分析服务的合作伙伴共享您的个人识别信息（指可以识别您身份的信息，通过它可以与您联系或识别您）。但是，我们可能会在匿名化后与授权的广告和分析服务合作伙伴分享您的个人信息，包括：网络识别信息（IP地址）、软件版本号、软件包名、软件安装列表、设备连接信息（电信运营商、网络环境）、设备属性信息（设备制造商、设备型号、操作系统版本、IMEI、OAID、IMSI、传感器、设备序列号），以帮助他们提高广告的有效触达率，而不识别您的个人。`,
  t44: `第三方SDK接入服务所需的权限。我们的产品和服务可能包括第三方的产品和服务，以及指向第三方网站的链接。当您使用这些产品或服务时，可能也会收集您的信息。在用户同意授权之后，个人信息将被发送给第三方。当第三方的身份发生变化时，将通过弹出窗口再次获得用户的同意，我们将与第三方分享个人信息。`,
  t45: `（2）转让`,
  t46: `除非以下情况外，我们不会将您的个人信息转让给任何公司、组织或个人：`,
  t47: `1. 在获得您的明确同意后，我们将向其他方转让您的个人信息；`,
  t48: `2. 在涉及合并、收购或破产清算时，如果涉及个人信息的转让，我们将要求持有您个人信息的新公司或组织继续受本隐私政策的约束。否则，我们将要求该公司或组织再次寻求您的授权和同意。`,
  t49: `（3）公开披露`,
  t50: `我们只会在以下情况下公开披露您的个人信息：`,
  t51: `1. 在获得您的明确同意后；`,
  t52: `2. 法律披露：我们可能根据法律、程序、诉讼或政府机关的强制性要求公开披露您的个人信息。`,
  t53: `（4）可能涉及外部共享的个人信息类型包括设备识别代码和产品版本信息；可能涉及公开披露的个人信息类型包括观看电视剧和观看时长。`,
  t54: `我们了解与向公众分享、转让和公开披露个人信息相关的相应法律责任，并将采取合理措施确保个人信息的安全。`,
  t55: `4、我们如何保护和保存您的个人信息`,
  t56: `（1）技术保护措施`,
  t57: `我们非常重视您个人信息的安全，将努力采取符合行业标准的各种合理安全措施，保护您的信息免受泄漏、损坏或丢失，包括但不限于 SSL、隐私信息的加密存储和数据中心的访问控制。我们将使用加密技术增强个人信息的安全性；我们将使用可信的保护机制防止对个人信息的恶意攻击；我们将部署访问控制机制，确保只有授权人员可以访问个人信息。`,
  t58: `（2）安全管理体系`,
  t59: `我们建立了以数据生命周期为中心的行业领先的数据安全管理体系，从组织构建、系统设计、人员管理、产品技术和个人信息安全影响评估等多个维度加强了整个系统的安全性。`,
  t60: `我们还对可能接触到您信息的员工或外包人员实施了严格的管理措施，包括但不限于根据其职位实施不同的权限控制、与其签订保密协议以及监控其操作。`,
  t61: `（3）账户保护`,
  t62: `您的账户具有安全保护功能，请保管好您的账号和密码信息。如果您发现您的账户被盗或个人信息泄漏，请及时联系我们，以便我们采取适当措施。`,
  t63: `（4）信息安全事件处理`,
  t64: `尽管上述安全措施已经实施，但也请您理解，在信息网络上没有绝对安全的措施。`,
  t65: `如果不幸发生个人信息安全事件，我们将按照法律法规的要求通知您：安全事件的基本情况和可能造成的影响，我们已经或将要采取的处理措施，建议您独立防范和减轻风险的措施以及为您提供的补救措施。我们将通过电话或通知向您告知事件的相关情况。如果难以逐一通知个人信息主体，我们将采取合理、有效的措施发布公告。同时，我们还将按照监管要求报告个人信息安全事件的处理情况。`,
  t66: `（5）存储期限`,
  t67: `我们仅在中国大陆存储您的个人信息。我们将采取一切合理可行的措施，确保不收集无关的个人信息。我们仅在法律规定的最短期限内保留您的个人信息，以实现本政策中规定的目的，除非法律要求或允许延长保留期限。`,
  t68: `超过保留期限后，我们将根据法律要求删除您的个人信息或对其进行匿名化处理。`,
  t69: `5、您的权利`,
  t70: `根据中国相关法律、法规和标准，我们保障您行使以下对您个人信息的权利：`,
  t71: `（1）访问您的个人信息`,
  t72: `您有权通过您自己的用户中心访问您的个人信息。请参考（VI）我们如何回应您的请求，除非法律法规另有规定。`,
  t73: `（2）更正您的个人信息`,
  t74: `当您发现我们处理的关于您的个人信息有误时，您有权要求我们进行更正。有关如何更正个人信息的信息，请参见（6）我们如何回应您的请求。`,
  t75: `（3）删除您的个人信息。在以下情况下，您可以要求我们删除您的个人信息：`,
  t76: `1. 我们处理个人信息违反法律法规；`,
  t77: `2. 我们在未经您同意的情况下收集和使用您的个人信息；`,
  t78: `3. 我们处理个人信息违反了与您的约定；`,
  t79: `4. 您不再使用我们的产品或服务，或者您已经注销了您的账户；`,
  t80: `5. 我们将不再为您提供产品或服务。`,
  t81: `如何删除个人信息可以在（VI）我们如何响应您的请求中找到。如果我们决定遵守您的请求，我们还将通知已从我们处获取您个人信息的实体及时删除，除非法律法规另有规定或您独立授权。`,
  t82: `当您从我们的服务中删除信息时，我们可能不会立即删除备份系统中的相应信息，但我们将在备份更新期间删除此信息。`,
  t83: `（4）撤销同意并禁用使用功能`,
  t84: `您可以通过禁用设备功能来更改我们继续收集个人信息的授权范围或撤销您的授权。您还可以通过注销账号撤销我们继续收集您个人信息的所有授权。如何撤销同意并禁用使用功能可在（VI）我们如何响应您的请求中找到。`,
  t85: `（5）个性化推荐管理`,
  t86: `1. 管理个性化广告信息推荐`,
  t87: `个性化广告是一种广告展示技术，广告商根据您在应用程序中的偏好进行智能推荐。如果您不想接受个性化广告推荐，您可以选择在【我的】-【系统设置】-【个性化广告推荐】开关上选择是否接收个性化广告推荐。关闭后，您看到的广告相关性将减少。`,
  t88: `2. 管理个性化内容推荐`,
  t89: `为了确保您能观看到您感兴趣的内容，我们提供了个性化内容推荐功能。如果您不想接受个性化内容推荐，您可以通过使用【我的】-【系统设置】-【个性化内容推荐】开关来关闭个性化内容推荐。关闭后，首页将不显示推荐频道。切换到已选频道将降低内容相关性。`,
  t90: `（6）账号注销`,
  t91: '您可以在【我的】-【系统设置】-【注销账号】中取消您的账号。您可以随时取消您之前注册的账号，请参阅“注销须知”了解详细信息。',
  t92: `取消账号后，我们将停止向您提供产品或服务并删除您的个人信息，除非法律法规另有规定。`,
  t93: `在以下情况下，根据法律要求，我们将无法满足您的个人信息删除请求：`,
  t94: `1. 直接与国家安全、国防安全有关；`,
  t95: `2. 直接与公共安全、公共卫生、重大公共利益有关；`,
  t96: `3. 直接与刑事侦查、起诉、审判和判决执行有关；`,
  t97: `4. 有充分证据表明您存在主观恶意或滥用权力；`,
  t98: `5. 响应您的请求将对您或其他个人或组织的合法权益造成严重损害；`,
  t99: `6. 涉及商业秘密。`,
  t100: `（7）响应您上述请求`,
  t101: '您可以通过手机上的“设置”功能选择要禁用的权限。',
  t102: '您还可以通过点击“我的” - “关于我们” - “联系客服” 进行电话呼叫，然后联系客服进行个人信息查询、个人信息更正、个人信息删除、用户账号注销、以及撤销已同意授权等用户操作。为了保证安全，您可能需要提供书面请求或其他方式证明您的身份。在处理您的请求之前，我们可能会首先要求您进行身份验证。',
  t103: `我们将在十五个工作日内提供答复。如果您不满意，您还可以通过联系客服进行申诉。`,
  t104: `6、我们如何处理儿童的个人信息`,
  t105: `我们的产品、网站和服务主要面向成年人。我们高度重视儿童和未成年人的个人信息保护。如果您是未满18周岁的未成年人，在使用本平台上的相关服务之前，您应在父母或其他监护人的监督和指导下阅读并同意本隐私政策，并在监护人同意的情况下使用我们的服务或向我们提供信息。`,
  t106: `对于我们在父母或监护人同意的情况下收集未成年人使用我们产品或服务的个人信息的情况，我们仅在法律法规允许、明确经父母或监护人同意、或者为了保护未成年人而必要时才使用、分享、转让或披露该信息。如果我们发现未事先获得可验证的父母同意而收集了未成年人的个人信息，我们将尽快删除相关信息。`,
  t107: `7、如何全球转移您的个人信息`,
  t108: `目前，我们不会跨境传输或存储您的个人信息。如果未来需要跨境传输或存储，我们将告知您信息输出的目的、接收方、安全措施以及信息输出的安全风险，并获得您的同意。`,
  t109: `8、如何更新本政策`,
  t110: `在更新本隐私政策后，本平台将在您登录和版本更新时以通知、弹窗、网站公告等形式向您展示更新后的隐私政策和内容，以便您及时了解最新版本的本隐私政策。我们将严格遵循向您披露的隐私政策收集和使用规则，进行个人信息处理活动。如果个人信息使用目的发生变更，我们将再次获得您的同意。如果您继续使用我们的服务，即表示您同意接受本政策的修订内容。`,
  t111: `9、本政策的适用`,
  t112: `本产品的所有服务均受本政策约束。`,
  t113: `当您首次登录此产品时，我们将提示您阅读本政策并获得您的同意。除非另有约定或法律要求，本隐私政策不适用于${window.company}科技及本产品的注册版权所有者通过链接到其他第三方的产品或服务。您使用这些第三方服务（包括您向这些第三方提供的任何个人信息）将受到这些第三方服务和隐私政策的条款约束（而不是本隐私政策）。请仔细阅读这些第三方的条款以了解具体规定。请妥善保护您的个人信息，仅在必要时向第三方提供。`,

  p1: `本隐私政策（以下简称本政策）于2023年3月15日发布并生效，最近修订于2023年4月10日。本政策是${window.region}${window.company}科技有限公司（以下简称“${window.company}科技”、“${window.company}”或“我们”）与用户（也称为“您”）就客户端产品“Hippo Theater”（以下简称“本产品”）相关隐私保护事项达成的有效合同。通过点击确认本协议或以其他任何方式选择接受本协议，您已与${window.company}科技及${window.company}达成协议，并同意接受本政策以下全部条款的约束。`,
  p2: `${window.region}${window.company}科技有限公司是本产品的注册版权所有者，并依法享有本产品的版权。`,
  p3: `本产品由${window.company}科技和${window.company}共同运营，为您提供服务并享有权利，依法履行义务，并为您承担法律责任。`,
  p4: `本政策将帮助您了解以下内容：`,
  p5: `1. 我们如何收集和使用您的个人信息`,
  p6: `2. 我们如何使用 Cookie 和类似技术`,
  p7: `3. 我们如何分享、转让和公开披露您的个人信息`,
  p8: `4. 我们如何保护您的个人信息`,
  p9: `5. 您的权利`,
  p10: `6. 我们如何处理儿童的个人信息`,
  p11: `7. 如何全球转移您的个人信息`,
  p12: `8. 如何更新本政策`,
  p13: `9. 本政策的适用`,
  p14: `10. 争议解决`,
  p15: `${window.company}科技和本产品的注册版权所有者非常重视个人信息的保护。当用户（简称'您'）使用我们的产品或服务时，我们可能会收集和使用您的相关信息。一旦您选择使用我们的产品或服务，即表示您承认并接受这些条款的现有内容及可能随时进行的任何更新。如果您不同意，应终止使用本产品的服务。请在使用我们的产品之前仔细阅读和理解本隐私政策。`,
  p16: `1、请仔细阅读和理解本协议中规定的所有权利和限制。一旦您安装、复制、下载、访问或以其他方式使用本产品，即视为接受本协议，并同意受本协议条款的约束。您必须确保从指定网站下载或使用本软件产品，并从合法发布的媒体获得。否则，我们将不对由此造成的任何潜在风险和损失负责。我们提醒您，如果您未满18岁或没有完全民事行为能力，请与您的法定监护人一起阅读本协议，并特别注意与未成年人使用本服务相关的条款。否则，除法律明确规定的情况外，我们不承担因随后的注册、使用本服务或支付充值而可能产生的任何不利后果。我们有权在发现此类情况后终止双方之间的用户协议。`,
  p17: `2、权利声明`,
  p18: `1. ${window.region}${window.company}科技有限公司是本软件的注册版权所有者，并依法享有本软件的版权。`,
  p19: `2. 未经${window.company}科技和本软件的注册版权所有者书面同意，用户不得独立实施、利用、转让或授权任何第三方实施、利用、转让前述软件版权和其他知识产权以谋取任何营利或非营利目的。${window.company}科技和本软件的注册版权所有者保留追究前述未经许可行为的权利。`,
  p20: `3. 禁止逆向工程、反编译和反汇编：用户不得对本软件产品进行逆向工程、反编译或反汇编，不得修改程序文件中编译的任何资源。除法律法规明确允许外，用户必须遵守本协议的限制。`,
  p21: `4. 组件分割：本软件产品的许可是作为整体使用的，并且用户不得将每个部分分开用于任何目的。`,
  p22: `5. 单独授权：如果需要商业销售、复制、分发，包括但不限于软件销售、预安装、捆绑等，必须从软件版权所有者获得单独的书面授权和许可。`,
  p23: `6. 保留权利：本协议中未明确授权的所有其他权利仍归${window.company}科技所有，用户在使用其他权利时必须获得${window.company}科技的书面同意。`,
  p24: `7. 本软件提供的视频内容仅代表作者的立场和观点，并与${window.company}科技和软件的注册版权所有者无关。作者应承担所有法律责任。`,
  p25: `3、用户说明`,
  p26: `1. 软件功能：搜索和观看短剧；充值、下载和购买短剧；观看并保存观看进度。`,
  p27: `2. 关于数字内容支付：用户付款产生的所有收入将在按规定扣除第三方支付机构收取的费用后由${window.company}科技收取。本软件内的数字内容包括免费内容和付费内容，分为限时免费内容和永久免费内容。${window.company}科技有权决定所提供服务的标准和收费方式，并在相关服务页面上提供有关定价标准的提示。用户有权选择是否支付相应费用继续使用本软件提供的付费服务。如果您是18岁以下的未成年人（特别是14岁以下的未成年人），您应在法定监护人的同意下进行充值。在本软件上充值后，用户将获得相应的观看点数，可用于购买本软件提供的付费服务。您对本协议的同意应视为您同意授权${window.company}科技自动购买下一集的内容和批量购买系列内容作为您的付费服务。一旦用户成功充值（包括获得监护人同意的未成年人），除非有不可抗力、协议约定或相关法律法规的情况，用户不得以非法理由要求全额或部分退款。`,
  p28: `3. 软件修改和升级：本软件的版权所有者保留随时为用户提供修改和升级版本的权利。用户选择并确认后，软件将进行升级和更新，并通过使用运营商的网络产生相应的数据流量费用，由运营商收取。`,
  p29: `4. 用户应依法使用本软件并遵守本协议。用户未经授权不得从事以下行为，包括但不限于：`,
  p30: `(1) 删除或更改本软件上的所有权管理电子信息；`,
  p31: `(2) 故意规避或损害版权所有者为保护本软件版权而采取的技术保护措施；`,
  p32: `(3) 利用本软件误导和欺骗他人；`,
  p33: `(4) 通过删除、修改、添加或干扰计算机信息系统的功能而违反国家法规，导致计算机信息系统功能异常；`,
  p34: `(5) 未经授权访问计算机信息网络或使用计算机信息网络资源；`,
  p35: `(6) 未经授权删除、修改或添加计算机信息网络功能；`,
  p36: `(7) 未经授权删除、渗透、修改或添加存储、处理或传输于计算机信息网络中的数据和应用程序；`,
  p37: `(8) 干扰本软件系统或网站的正常运行，故意传播计算机病毒等破坏性程序；`,
  p38: `(9) 任何其他危害计算机信息网络安全的行为；`,
  p39: `(10) 其他异常使用行为。`,
  p40: `5. 对于从非指定网站下载或从非法分发的媒体获取的本软件产品，${window.company}科技无法保证软件是否感染了计算机病毒，是否隐藏着伪装的木马程序或黑客软件。用户使用此类软件可能会带来不可预测的风险。建议用户不要轻易下载、安装或使用。${window.company}科技和本软件的注册版权所有者对由此产生的任何法律责任不承担责任。`,
  p41: `6. 隐私保护`,
  p42: `${window.company}科技承诺遵守隐私政策（也称为“隐私协议”）和适用法律，并制定了以下四项隐私保护原则，以指导我们处理产品中涉及用户隐私和信息的问题：`,
  p43: `(1) 在收集您的信息之前，我们将清楚告知您信息收集的方法、范围和目的，并取得您的同意。我们只使用我们收集的信息为用户提供有价值的产品和服务。我们绝不会超出收集您个人信息的范围或频率，例如联系方式、位置、身份证、面部信息等，并且绝不会收集不必要的信息或对本软件服务没有合理应用场景的信息；`,
  p44: `(2) 开发符合隐私标准和隐私实践的产品。未经用户同意，不得与其他应用软件共享或使用个人信息，例如设备识别信息、产品浏览历史、搜索使用习惯和常用软件应用列表。绝不使用收集的用户搜索、浏览历史、使用习惯等个人信息进行定向推广或精准营销，而不通知或明显提示用户，并且不提供用户关闭此功能的选项；`,
  p45: `(3) 透明地收集个人信息；`,
  p46: `(4) 竭尽全力保护我们获得的用户信息。`,
  p47: `基于此，您同意授权${window.company}科技使用您的用户头像和昵称，以便我们更好地为您服务。`,
  p48: `7. 成为VIP会员后，在会员期内，您可以观看本平台上的所有短剧内容。`,
  p49: `8. 在安装和运行本软件期间，${window.company}科技绝不会要求任何与用户当前使用场景无关的权限。此外，用户明确拒绝权限申请后，绝不应频繁申请启用与当前服务场景无关的权限，例如联系人列表、位置、短信、录音、相机等，以强迫用户同意启用与本软件无关的权限。当用户不使用相关功能或服务时，不应提前申请启用用户联系人、位置、短信、录音、相机等权限，或者申请启用与本软件产品的业务功能或服务不相关的联系人、位置、短信、录音、相机等权限。剪贴板权限仅在用户选择复制客服电话号码的场景下使用。如果用户不选择复制，${window.company}科技绝不会向用户申请启用剪贴板权限。`,
  p50: `9. 用户有权取消其个人帐户信息。用户取消其个人帐户信息后，${window.company}科技将删除用户在本软件中存储的个人帐户信息（详情请参阅相关取消通知）。`,
  p51: `10. 当您重新安装手机系统或清理手机应用软件时，您的所有用户信息将会丢失，包括但不限于：个人账号信息、搜索或浏览剧集的记录、已下载或已购买的剧集、观看记录、观看进度、会员权益，同时，您个人账号内的所有观看记录将会被重置为零。您知晓这是产品特性决定的，但您可以通过将个人账号与手机号关联来避免在重新安装手机系统或清理手机应用时丢失相关用户信息。当您未能将个人账号与手机号关联时，在用户信息丢失事件发生时，${window.company}科技及本软件的版权所有者将不承担责任。`,
  p52: `11. 您不得在本软件平台上以任何方式从事非法活动或进行异常消费，也不得通过利用系统漏洞、程序缺陷、人为操作错误等手段进行。您不得使用恶意软件或任何其他手段扰乱本软件平台的正常运行。您不得从事任何违背诚信原则的活动。否则，一旦本软件平台认定您存在上述行为，${window.company}科技有权直接冻结或取消您的账号，且不会退还账户余额。由此造成的一切损失将由您承担。此外，${window.company}科技仍保留根据法律追究您的法律责任的权利。如果您的行为给${window.company}科技造成损失，您应赔偿${window.company}科技的全部损失。如果您涉嫌犯罪，${window.company}科技有权将相关线索移交司法部门处理。`,
  p53: `我们高度重视个人信息的保护以及未成年人的合法权益，积极推动他们的健康成长。未成年人（特别是未满十四周岁的人）未经父母（监护人）陪同和同意使用本协议项下的服务时，一经发现，应立即由其父母（监护人）进行通知。我们将协助其父母（监护人）处理其注册账号和账户，确保履行监护责任和义务。`,
  p54: `4、用户和第三方的知识产权保护`,
  p55: `1. 在使用本软件包含的服务过程中，用户可能需要通过发表评论等各种方式向${window.company}科技提供内容。用户在本软件上发布的所有原创内容归用户本人所有。用户发表的评论仅代表其观点，与本软件的注册版权所有者或${window.company}科技无关。`,
  p56: `2. 用户不得侵犯知识产权，包括他人的版权以及其他权利。如果用户发布的内容引起任何法律纠纷，责任将由用户自行承担。`,
  p57: `3. 对于用户在本软件上发布的任何内容，用户同意授权${window.company}科技拥有全球范围内的免费、永久、非独占的使用权利。用户许可${window.company}科技对任何侵权行为采取单独的法律行动（包括但不限于诉讼、举报、发出律师函等）。`,
  p58: `4. 如果用户的评论侵犯了第三方的版权或其他权利，或者违反了法律规定，在第三方提出异议或通过内容审核流程发现后，${window.company}科技有权删除相关内容，并有权追究或协助第三方追究用户的法律责任。`,
  p59: `5. 未经${window.company}科技书面许可，任何实体不得使用任何爬虫程序、蜘蛛程序、人形程序、渗透技术、黑客程序及其他自动设备，或者手动程序侵入、读取、复制、存储本软件或其中包含的任何内容，或者从事任何违法或不诚实的活动。否则，${window.company}科技有权直接冻结或取消您的账号，且有权不退还账户余额。由此造成的所有损失将由您单方面承担。此外，${window.company}科技仍有权依法追究您对${window.company}科技因您上述行为造成的一切损失的法律责任，以有效保护${window.company}科技的合法权益。`,
  p60: `5、内容版权声明`,
  p61: `${window.company}科技承诺遵守并尊重当前中国法律、法规及其他规范性文件下版权所有者的保护。作为权利人，当您发现提供的内容侵犯了您的合法权益时，您应首先根据处理流程发送给我们一份“权利通知”至 cs{'@'}ishugui.com 或致电客户服务电话 400-118-0066。我们将根据中国法律法规和政府规范性文件采取措施，删除相关内容或断开或封锁相关链接。在采取上述措施后，您不得追究我们及本软件的注册版权所有者的任何法律责任。`,
  p62: `6、免责声明和责任限制`,
  p63: `1. 用户确认已了解本软件的所有功能以及实施每个功能所需的操作。他们自愿根据自己的需求选择使用本软件和相关服务。根据法律规定，您将完全承担使用本软件和相关服务的风险和所有后果。本软件的注册版权所有者和${window.company}科技不承担责任。`,
  p64: `2. 本软件经过详细测试，但我们不能保证与所有软件和硬件系统完全兼容，也不能保证本软件完全没有错误。如果存在兼容性问题和软件错误，用户可以拨打客服热线电话 400-118-0066 报告情况并获得技术支持。如果无法解决兼容性问题，用户可以删除本软件。`,
  p65: `3. 在法律允许的最大范围内，本软件的注册版权所有者和${window.company}科技对您使用或无法使用本软件所造成的任何损害和风险不承担责任，包括但不限于直接或间接的个人损害、商业利润损失、贸易中断、商业信息丢失或任何其他经济损失。`,
  p66: `4. 本软件的注册版权所有者和${window.company}科技不对由于电信系统或互联网网络故障、手机故障或病毒程序运行等原因造成的信息损坏或丢失、手机系统问题或任何其他不可抗力原因导致的任何损失负责。`,
  p67: `5. 如果用户违反本协议的规定，给本软件的注册版权所有者和${window.company}科技造成损失，${window.company}科技有权采取包括但不限于中断用户使用许可、停止提供服务、限制使用以及追究法律责任等措施。`,
  p68: `7、法律和争议解决`,
  p69: `1. 本协议受中华人民共和国法律管辖。`,
  p70: `2. 任何因本协议引起或与之相关的争议应通过各方友好协商解决；如果协商失败，任何一方均有权将争议提交仲裁委员会仲裁。`,
  p71: `8、其他条款`,
  p72: `1. 如果本协议的任何条款因任何原因完全或部分无效或不可执行，或违反任何适用法律，则该条款应被视为已删除，但本协议的其余条款仍应有效且具有约束力。`,
  p73: `2. ${window.company}科技有权根据相关法律法规的变化以及公司经营状况和战略的调整随时修改本协议。修订后的协议将在网站上公布并附加在新版本的软件上。当出现争议时，以最新的协议文本为准。如果用户不同意更改，可以自行删除软件。如果用户继续使用本软件，则视为您已接受对本协议的更改。`,
  p74: `3. ${window.company}科技有权解释和修改本协议，但不得超出法律所允许的最大范围。`,

  login_first: `您还未登录，请先登录`,
  sessionTimeout: `会话已过期，请重新登录`,
  unknown_err: `未知错误`,
  way: `方式`,

  starCardPlan: `星达人计划`,
  starCardPrivate: `星达人权益`,
  starCardIntroduction: `星卡是剧宝盆推广系统的月票，拥有星卡，才可以享有剧宝盆推广系统一系列的收益福利。星卡分为体验星卡和尊享星卡，百亿补贴期间，星卡用户均可获得现金补贴。`,

  banQuanZhongChou: `版权众筹`,
  banQuan1: `1.3倍固定收益，更多灵活奖励`,
  banQuan2: `1.5倍固定收益，更多灵活奖励`,

  enterPhoneNumber: `请输入您的手机号码`,
  enterRightNumber: `请输入正确的手机号码`,
  pwdNotNull: `验证码不能为空`,
  checkAgreement: `请先勾选同意隐私条款和用户协议`,
  second: `秒`,
  sendSuccess: `发送成功`,
  codeValid: `获取验证码`,

  promoteCount: `推广人数`,
  today: `今日`,
  promoteList: `推广列表`,
  nickName: `昵称`,
  level: `层级`,
  register: `注册`,
  noMore: `暂无更多内容`,
  page: `页`,
  drawout: `发起提现`,
  logining: `正在登陆...`,
  shortMoive: `短剧`,
  otherLogin: `其他方式登录`,

  uploading: `上传中···`,
  timeout: `连接超时，请检查网络`,
  uploadLimitOne: `上传图片只能单张选择`,

  drawoutNotice: `<p>1.请如实填写提现相关信息。如因信息不正确造成任何损失，平台将不承担责任。</p>
  <p>2.提现订单需要财务审核，到账时间可能会有一定延迟。</p>
  <p>3.单笔交易的最低提现金额 ${window.currency}$extractSingleMin。</p>
  <p>4.单笔交易的最高提现金额 ${window.currency}$extractSingleMax。</p>
  <p>5.手续费 $extractChargeRate ，从提现金额中扣除。</p>
  <p>6.每日最大提现 $extractDailyCount 笔订单</p>
  <p>7.提现后24小时内到账。</p>`,

  submitItem: `提交`,
  caresure: `注意事项`,
  server_bank: `银行`,
  server_bankCardNo: `银行卡号`,
  server_bankCardHolder: `账户名字`,
  server_email: `邮箱`,
  server_phone: `电话`,

  pleaseInputPwd: `请输入密码`,
  useCodeLogin: `切换验证码登录`,
  usePwdLogin: `切换密码登录`,
  pwdLimitTip: `密码长度为6~20位`,
  pleaEnterInviteCode: `请输入邀请码`,
  setPwd: `设置登录密码`,
  pwdPlaceholder: `请输入6-20位密码`,
  optSuccess: `操作成功`,
  unit: window.currency,

  Balance: `余额`, // Balance
  WithdrawAmountTip: `提款金额不能为空`, // Withdrawal Amount cannot be empty
  WithdrawAmountTipMin: `您的余额小于最低提款金额`, // Your balance is less than the minimum withdrawal amount
  WithdrawAmountTipDecimal: `提款金额不能超过两位小数`, // The withdrawal amount cannot exceed two decimal places
  TransferAmountTip: `转账金额不能为空`, // Transfer amount cannot be empty
  Submit: `提交`,

  BankOfDeposit: `存款银行`,
  BankOfDepositTip: `存款银行不能为空`,
  BankCardNumber: `银行卡号`,
  BankCardNumberTip: `银行卡号不能为空`,
  userName: `username`,
  userNameTip: `username不能为空`,

  TransferUserID: `转账用户ID`,
  TransferUserIDTip: `转账用户ID不能为空`,
  ReTransferUserID: `二次输入转账用户ID`,
  TransferUserIDTips: `二次输入转账用户ID与转账用户ID不一致`,

  VerificationCode: `验证码`,
  VerificationCodeTip: `验证码不能为空`,
  GetVerificationCode: `获取验证码`,

  Precautions: `注意事项`, // Precautions
  PrecautionsTip1: `1. 请如实填写与提现相关的资料。若因填写错误信息造成任何损失，平台将不负责任。`, // 1.Please truthfully fill in the withdrawal related information. If any loss is caused due to incorrect information, the platform will not be held responsible.
  PrecautionsTip2: `2. 提现申请需经财务审核，到账时间可能会有所延迟。`, // 2.Withdrawal orders require financial review, and there may be a certain delay in the arrival time.
  PrecautionsTip3: `3. 单次交易的最低提现金额为 slot。`, // 3.Minimum withdrawal amount for a single transaction 1.
  PrecautionsTip4: `4. 单次交易的最高提现金额为 slot。`, // 4.Maximum withdrawal amount per transaction 1.
  PrecautionsTip5: `5. 手续费 slot，从提现金额中扣除。`, // 5.Commission 1,deduction from withdrawal amount.
  PrecautionsTip6: `6. 每日最多可提现 slot 次。`, // 6.Maximum daily withdrawal 1 orders
  PrecautionsTip7: `7. 提现后 24 小时内到账。`, // 7.Received within 24 hours after withdrawal.

  confirmPay: `确认支付 $y`,
  goPay: `立即支付 $y`,
  couponState: `已使用或不匹配当前类型`,
  couponNo: `当前卡劵不可用`,
  coupinErr: `卡劵错误`,
  couponUse: `优惠劵抵扣 $y`,
  BalanceDeductions: `余额抵扣 $y`,
  ExchangeStarCard: `兑换星卡`,
  EnterMembership: `兑换成功获得-x天体验星卡`,
  ExchangeNotUsed: `兑换码未使用`,
  ExchangeUsed: `兑换码已使用`,
  noExchange: `兑换码不存在`,

  PleaseAmount: `请选择参与金额：`,
  PaymentAgreement: `支付即视为您的同意`,
  DramaAgreement: `《剧有钱星卡服务协议》`,

  setPayment: `设置收款方式`,
  saveInfo: `保存信息`,

  myBuy: `我要买`,
  mySell: `我要卖`,
  placeOrder: `我要挂单`,
  whole: `全部`,
  myOrder: `我的订单`,
  anonymousUser: `匿名用户`,
  deal: `成交`,
  sell: `出售`,
  integral: `积分`,
  demand: `需求`,
  minute: `分钟`,
  sellOut: `卖出`,
  purchase: `买入`,
  pendingOrders: `有待处理的订单`,
  confirmOrder: `确定下单`,
  choosePaymentMethod: `请选择支付方式`,
  back: `返回`,
  orderGenerated: `订单已生成`,
  please: `请`,
  paymentInformation: `内确认买家付款信息`,
  reportExceptions: `上报异常`,
  sellerToConfirm: `等待卖家确认收款`,
  ordersCannotStage: `此阶段不可取消订单`,
  orderCompleted: `订单已完成`,
  contactustomer: `如有异议请联系客服进行调解`,
  customerServiceMediation: `客服调解`,
  confirmCompletion: `确定完成`,
  PleaseWaitPayment: `请等待买家付款`,
  reportCorrect: `上报订单异常,是否?`,
  addMethod: `添加收款方式`,
  selectType: `选择类型`,
  sellPoints: `卖出积分`,
  sellUsers: `出售账户中的积分给其他用户`,
  purchasePoints: `收购积分`,
  canPointsUsers: `可以收购其他用户积分`,
  messageBoard: `留言板`,
  you: `您`,
  reply: `回复`,
  enterBelow: `请在下方输入回复信息`,
  viewDetails: `查看详情`,
  paymentMethod: `收款方式`,
  addPaymentInformation: `添加收款信息`,
  paymentDeadline: `付款时限`,
  totalAmount: `总额`,
  price: `单价`,
  tradingPrecautions: `交易注意事项`,
  afterQuantity: `1.确认下单后将按订单数量锁定您的积分。`,
  confirmCancelled: `2.请在规定时间内确认完成交易，超时将自动取消。`,
  cancellationOrder: `取消订单`,
  CancelOrder: `取消挂单`,
  transactionDetails: `交易详细`,
  myPendingOrder: `我的挂单`,
  orderType: `订单类型`,
  myTransaction: `我的交易`,
  orderStatus: `订单状态`,
  buyPoints: `买入积分`,
  release: `发布`,
  locking: `锁定`,
  end: `结束`,
  removeFromShelves: `下架`,
  establish: `创建`,
  approachingTimeout: `即将超时`,
  overtime: `超时`,
  buyerConfirmed: `买家已确认`,
  sellerConfirmed: `卖家确认`,
  successfulTrade: `交易成功`,

  createOrder: `创建买单`,
  createSellOrder: `创建卖单`,
  enterPpurchaseQuantity: `输入购买数量`,
  enterSale: `请输入出售数量`,
  pointsBalance: `积分余额`,
  setMaxUantity: `设置最大数量`,
  setPurchasePrice: `设置购入价格（总价）`,
  setSalePrice: `设置出售价格（总价）`,
  rangePrice: `积分单价范围`,
  confirmPeddingOrder: `确定挂单`,
  pointsRange: `积分单价超出范围`,
  orderSuccessfully: `挂单成功`,
  OrderErr: `订单异常`,
  OrderOverTime: `订单超时`,
  CustomeRservice: `如有异议请联系客服进行调解`,
  UpdataPedding: `修改挂单`,
  CustomerService: `客服调解`,
  WaitingPayment: `等待付款`,
  Collectionpayment: `等待收款`,
  ConfirmCompletion: `确定完成`,
  ContactCustomer: `联系客服调解`,
  NumberPending: '今日剩余挂单次数',
  Edit: `修改`,
  Create: `创建`,
  Bill: '买单',
  SellBill: '卖单',
  PendingOrders: `待处理订单`,
  Reason: `原因`,
  Kong: `空`,
  USDTAmout: `提现到USDT后金额为$slot`,
  enterPassword: `请输入密码`,
  enterSecondPassword: `请输入确认密码`,
  confirmPassword: `两次密码不一致`,
  registerIn: `立即注册并登录`,
  LoginCode: `验证码登录`,
  LoginPassword: `密码登录`,
  goLogin: `去登录`,
  goRegister: `去注册`,

  WALLET: '钱包',
  THIRD_PAY: '第三方支付',
  Using: `使用中`,
  SwitchAccount: `切换账户`,
  AddAccount: `添加账户`,
  Finish: `完成`,
  ClearAccount: `您确定要清除您的账户吗？`,
  noPaymentChannel: `无支付渠道`,
  pleaSelectCoinType: `请选择币种类型`,
};
